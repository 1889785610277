<template>
  <div class="menu">
    <div class="flex title acenter">
      <img src="@/assets/images/logo.png" class="logo" style="width: 43px; height: 43px;margin-right: 20px;" />
      联营平台
    </div>
    <el-menu class="el-menu-vertical-demo" :default-active="activeMenu" active-text-color="#FFFFFF"
      background-color="#232B40" text-color="#FFFFFF" :router="true" @open="handleOpen" @close="handleClose">
      <template v-for="item in menu">
        <el-menu-item :index="item.name" @click="gorouter(item)" :key='item.id' v-if="!item.children"
          :class="[activeMenu == item.name ? 'active' : '']">
          <img style="width:28px" :src="getImageUrl(item.src)" alt="">
          <span :class="[activeMenu == item.name ? 'turnwhite' : '']">{{ item.title }}</span>
        </el-menu-item>
        <el-sub-menu v-else>
          <template #title>
            <img style="width:28px" :src="getImageUrl(item.src)" alt="">
            {{ item.title }}</template>
          <el-menu-item v-for="menus in item.children" :index="menus.name" :key='menus.id' @click="gorouter(menus)" style="
         padding-left: 65px !important;
    font-size: 15px;
">
            {{ menus.title }}</el-menu-item>
        </el-sub-menu>
      </template>
    </el-menu>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();
const keys = ref('')
const activeMenu = computed(() => {
  if (route.meta.activeMenu) return route.meta.activeMenu
  // return route.path;
});
const gorouter = (item) => {
  console.log(item, '点击')
  activeMenu.value = item.name
}
const getImageUrl = (img) => {
  // 里面可以根据需求写逻辑
  return require(`../../../assets/images/${img}`)
};
// meun
const menu = ref([
  {
    id: 0,
    name: '/',
    title: '首页',
    src: ('Home.svg'),
  },
  {
    id: 1,
    name: '/authorizeMange',
    title: '授权管理',
    src: ('sdk.svg'),
  },
  {
    id: 100,
    name: '/addGameVersion',
    title: '添加版本',
    src: ('sdk.svg'),
  },
  {
    id: 2,
    name: '/versionMange',
    title: '版本审批',
    src: ('sdk.svg'),
  },
  {
    id: 3,
    name: '/PartitionM',
    title: '分区管理',
    src: ('sdk.svg'),
  },
  {
    id: 4,
    name: '/serviceMange',
    title: '服务商管理',
    src: ('servicemsn.svg'),
  },
  {
    id: 5,
    name: '/GMManagement',
    title: 'Gm管理',
    src: ('gmmsn.svg'),
  },
  {
    id:6,
    name: '/orderM',
    title: '订单管理',
    src: ('gmmsn.svg'),
  },
  {
    id: 7,
    name: '/accountMange',
    title: '财务管理',
    src: ('gmmsn.svg'),
    children: [
      {
        id: 1,
        name: '/accountMange',
        title: '账户管理',

      },
      {
        id: 2,
        name: '/orderMange',
        title: '订单管理',
      },
      {
        id: 3,
        name: '/settleMange',
        title: '结算管理',

      },
      {
        id: 2,
        name: '/financeSet',
        title: '财务设置',
      }
    ]
  },

])
const handleOpen = (key, keyPath) => {
  keys.value = key
  console.log(key, keyPath, 'dddd');
  console.log(keys.value);
};
const handleClose = (key, keyPath) => {
  console.log(key, keyPath,);
};
</script>
<style scoped>
::v-deep .el-sub-menu__title {
  font-size: 16px;
  margin-left: 7px;
}

.title {
  width: 100%;
  height: 70px;
  font-size: 24px;
  color: white;
  margin-left: 23px;
}

img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

/* 选中颜色 */
.el-menu-item.is-active {
  background-color: #4F5566 !important;
}

.menu {
  background: #232B40;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}

.el-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  height: 100%;
}

.el-menu-item {
  padding-left: 10% !important;
  font-size: 16px;
}
</style>