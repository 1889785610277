import axios from "axios";
let DataURL = "https://api.tgs.plus";
// let DataURL = "http://192.168.1.166:5566";

import router from "../router/index";
// class jsonData {
//     constructor(data) {
//         this.vision = "1.0";
//         this.code = "200";
//         this.data = data;
//     }
// }
export function getToken() {

    if (document.cookie.length > 0) {
        // return  document.cookie
        let arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
        for (let i = 0; i < arr.length; i++) {
            let arr2 = arr[i].split("="); //再次切割
            //判断查找相对应的值
            if (arr2[0] == "token") {
                return arr2[1]; //保存到保存数据的地方
                console.log(arr2[1],'打印token')
            }
        }
        return null;
    }
    }
const ax = axios.create({
    timeout: 30000,
    headers: {
        "Content-Type ": "text/html; charset=utf-8;",
    },
    withCredentials: true,
});
ax.interceptors.request.use(
    (config) => {
        let token = getToken();
        const headers = {};
        // console.log(token, "token");
        if (token) {
            headers.token = token;
        }
        // const ip = getRedirectIp()
        // if (ip) {
        //     headers.redirectip = ip
        // }
        config.headers = headers;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

ax.interceptors.response.use(
    (res) => {
        const data = res;
        // console.log(data,'data')
        switch (data.data.errcode) {
            case "A0001":
                window.$message.error("登陆信息过期，请重新登陆！");
                setTimeout(() => {
                    router.push("/login");
                }, 1000);
                break;
            case "A0000":
                window.$message.error("参数有误");
                break;
            case "B0002":
                window.$message.error("系统异常");
                break;
        }
        return data.data;
    },
    (error) => {
        console.log("err" + error); // for debug
        return Promise.reject(error);
    }
);

const http = {
    post(url, data) {
        return ax.post(DataURL + url,data);
    },
    put(url, data) {
        return ax.put(DataURL + url,data);
    },
    get(url, data) {
        return ax.get(DataURL + url,data);
    },
    postFrom(url, data) {
        return ax.post(DataURL + url, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
    },
    get(url, data) {
        return ax.get(DataURL + url, { params: data });
    },
    get2(url, data) {
        return ax.get(DataURL + url, { params: data },
            { 'responseType': 'blob' });
    },
    post2(url, data) {
    
        // `${url}/${data}`
        return ax.post(`${DataURL}${url}?${data}`);
        // return ax.post(DataURL + url+'/'+data,);
    },

};

//导出
export default http;
export { DataURL };
