<template>
  <div class="common-layout">
    <el-container class="wh">
      <!-- 侧边 -->
      <el-aside width="277px">
        <AsideMenu></AsideMenu>
      </el-aside>
      <el-container>
        <!-- 头 -->
        <el-header style="background:#FFFFFF;" class="flex acenter w100">
          <div class="flex acenter w100">
            <Breadcrumb></Breadcrumb>
            <div class="account flex allcenter">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link flex allcenter">
                  <span class="accountname allcenter">
                    <img src="@/assets/images/setting.png" alt="" style="width: 24px;
              height: 24px;margin-right: 10px;">
                   {{ loginVo.nickname }} 
                    <el-icon class="el-icon--right"><arrow-down /></el-icon>
                  </span>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <!-- command="a" -->
                    <el-dropdown-item icon="SwitchButton" @click="goout">
                      退出登录
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </el-header>
        <!-- 主 -->
        <el-main style="background:#F0F2F5;">
          <router-view style="border-radius: 4px " />
        </el-main>
      </el-container>
    </el-container>
  </div>
  <!-- 退出登录 -->
  <el-dialog v-model="outLogin" title="是否确定退出？" width="380px" center :close-on-click-modal="false">
    <div style="width: 100%;text-align: center;" class="mb-5 mt-3">确定退出登录吗？</div>
    <div class="flex items-center justify-center">
      <el-button @click="outLogin = false" class="mx-5 canclebtn">取消</el-button>
      <el-button type="primary" @click="OUT" class="mx-5 confirmbtn">确定</el-button>
    </div>
  </el-dialog>
</template>
<script setup>
import Breadcrumb from "@/components/Breadcrumb"
import AsideMenu from "../views/Layout/components/AsideMenu.vue";
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from "vue-router";
let loginVo = JSON.parse(localStorage.getItem("loginVo"));
const router = useRouter();
const route = useRoute();
// 账号详情

// 退出代码
const outLogin = ref(false)
const goout = () => {
  outLogin.value = true
}
const OUT = () => {
  router.push('/Login')
  // 退出成功
  ElMessage.success('退出成功')
  localStorage.clear();
  // 清除用户信息
  document.cookie = "";
}
</script>
<style scoped lang='scss'> 
.common-layout {
  width: 100%;
  height: 100%;
}

.account {
  margin-left: auto;
  // width: 350px;
  height: 100%;
  margin-right: 20px;

  .el-dropdown {
    padding-left: 25px;

    // 首页下拉菜单
    .el-dropdown-link {
      cursor: pointer;
      font-size: 16px;
      // color: #FFFFFF;
    }

    .el-icon-arrow-down {
      font-size: 12px;
    }

    .accountname {
      color: #000000;
      display: flex;
    }
  }
}
</style>
 