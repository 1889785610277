<template>
<router-view/>
</template>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
}
* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    box-sizing: border-box;
    font-family: 'Microsoft YaHei';
    /* border: 1px solid blue; */
}

.el-form-item.is-required:not(.is-no-asterisk).asterisk-left>.el-form-item__label:before {
    content: "*";
    color: #2A88FF;
    margin-right: 4px;
}

html,
body {

    height: 100%;

}

</style>
