<template>
    <div class="ver">
        <span style="margin-right: 50px;">版本号:v0.1</span>
    </div>

    <div class="">联营平台临时部署版</div>
</template>
<script setup>
    
</script>
<style scoped lang='scss'>

.ver{
    color: #838282;font-size: 16px;
    width: 100%;
    text-align: right;
}
</style>
