<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="breadcrumb">
    <div>当前位置：</div>
    <el-breadcrumb class="app-breadcrumb">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
          <span v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="no-redirect">{{
            item.meta.title
          }}</span>
          <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {
      levelList: null
    }
  },
  watch: {
    $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith('/redirect/')) {
        return
      }
      this.getBreadcrumb()
    },
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      // const first = matched[0]

      // if (!this.isDashboard(first)) {
      //   matched = [{ path: '/index', meta: { title: '首页' }}].concat(matched)
      // }

      this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    },
    isDashboard(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim() === 'Index'
    },
    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {

  // color: #000000;
  height: 100%;
  display: flex;
  align-items: center;

  .btn {
    width: 70px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    line-height: 0;
    color: #C0C4CC;
    margin-right: 20px;
  }
}

.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  // line-height: 50px;
  margin-left: 8px;
  // line-height: 50px;

  a {
    // 父
    color: rgba(0,0,0,0.5);
    font-weight: 500;
    line-height: 50px;
  }

  .no-redirect {  
    color: #000000;
    cursor: text;
    font-weight: 500;
    line-height: 49px;
    font-size: 14px;
  }
}

.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all .5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-move {
  transition: all .5s;
}

.breadcrumb-leave-active {
  position: absolute;
}
</style>
