import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Home from '@/views/Home/home_index.vue';
import { getToken } from "../axios/http";
const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          title: "主页",
          activeMenu: "/"
        },
      },
      {
        path: '/authorizeMange',
        name: 'authorizeMange',
        meta: {
          title: "授权管理",
          activeMenu: "/authorizeMange"
        },
        component: () => import('@/views/Home/authorizeMange'),
        children: [
          {
            path: '/addAuthorize',
            name: 'addAuthorize',
            component: () => import('@/views/Home/addAuthorize'),
            meta: {
              title: "添加授权",
              activeMenu: "/authorizeMange"
            },
          },
          {
            path: '/authorizeD',
            name: 'authorizeD',
            component: () => import('@/views/Home/authorizeD'),
            meta: {
              title: "授权详情",
              activeMenu: "/authorizeMange"
            },
          }
        ]
      },
        //添加新版本
      {
        path: '/addGameVersion',
        name: 'addGameVersion',
        component: () => import('@/views/gameVersion/addGameVersion'),
        meta: {
          title: "添加版本",
          activeMenu: "/gameVersion/addGameVersion'"
        },
      },
      // 版本审批
      {
        path: '/versionMange',
        name: 'versionMange',
        meta: {
          title: "版本审批",
          activeMenu: "/versionMange"
        },
        component: () => import('@/views/Home/versionMange'),
        children: [

          {
            path: '/versionD',
            name: 'versionD',
            component: () => import('@/views/Home/versionD'),
            meta: {
              title: "版本详情",
              activeMenu: "/versionMange"
            },
          }
        ]
      },
      // 分区管理
      {
        path: '/PartitionM',
        name: 'PartitionM',
        meta: {
          title: "分区管理",
          activeMenu: "/PartitionM"
        },
        component: () => import('@/views/Home/PartitionM'),
        children: [

          {
            path: '/PartitionMD',
            name: 'PartitionMD',
            component: () => import('@/views/Home/PartitionMD'),
            meta: {
              title: "分区详情",
              activeMenu: "/PartitionMD"
            },
          }
        ]
      },
      {
        path: '/serviceMange',
        name: 'serviceMange',
        meta: {
          title: "服务商管理",
          activeMenu: "/serviceMange"
        },
        component: () => import('@/views/Home/serviceMange/serviceMange'),
        children: [
          {
            path: '/serviceD',
            name: 'serviceD',
            component: () => import('@/views/Home/serviceMange/serviceD'),
            meta: {
              title: "服务商详情",
              activeMenu: "/serviceMange"
            },
            children: [
              // sdk
              {
                path: '/sSdk',
                name: 'sSdk',
                component: () => import('@/views/Home/serviceMange/sSdk'),
                meta: {
                  title: "sdk",
                  activeMenu: "/serviceMange"
                },
                children: [
                  {
                    path: '/sSdkD',
                    name: 'sSdkD',
                    component: () => import('@/views/Home/serviceMange/sSdkD'),
                    meta: {
                      title: "sdk详情",
                      activeMenu: "/serviceMange"
                    },

                  }]
              },
              // 版本
              {
                path: '/sVersion',
                name: 'sVersion',
                component: () => import('@/views/Home/serviceMange/sVersion'),
                meta: {
                  title: "版本",
                  activeMenu: "/serviceMange"
                },
                children: [

                  {
                    path: '/sVersionD',
                    name: 'sVersionD',
                    component: () => import('@/views/Home/serviceMange/sVersionD'),
                    meta: {
                      title: "版本详情",
                      activeMenu: "/serviceMange"
                    },
                  }
                ]
              },
              // 分区
              {
                path: '/sPartition',
                name: 'sPartition',
                component: () => import('@/views/Home/serviceMange/sPartition'),
                meta: {
                  title: "分区",
                  activeMenu: "/serviceMange"
                }, children: [

                  {
                    path: '/sPartitionD',
                    name: 'sPartitionD',
                    component: () => import('@/views/Home/serviceMange/sPartitionD'),
                    meta: {
                      title: "分区详情",
                      activeMenu: "/serviceMange"
                    },
                  }
                ]
              },
              // GM账户
              {
                path: '/serviceGm',
                name: 'serviceGm',
                component: () => import('@/views/Home/serviceMange/serviceGm'),
                meta: {
                  title: "GM账户",
                  activeMenu: "/serviceMange"
                },
                children: [
                  {
                    path: '/serviceGmD',
                    name: 'serviceGmD',
                    component: () => import('@/views/Home/serviceMange/serviceGmD'),
                    meta: {
                      title: "GM账户详情",
                      activeMenu: "/serviceMange"
                    },
                  }
                ]
              },
            ]
          },
          {
            path: '/serviceD',
            name: 'serviceD',
            component: () => import('@/views/Home/serviceMange/serviceD'),
            meta: {
              title: "服务商详情",
              activeMenu: "/serviceMange"
            },
            children: [
              // sdk
              {
                path: '/sSdk',
                name: 'sSdk',
                component: () => import('@/views/Home/serviceMange/sSdk'),
                meta: {
                  title: "sdk",
                  activeMenu: "/serviceMange"
                },
                children: [
                  {
                    path: '/sSdkD',
                    name: 'sSdkD',
                    component: () => import('@/views/Home/serviceMange/sSdkD'),
                    meta: {
                      title: "sdk详情",
                      activeMenu: "/serviceMange"
                    },

                  }]
              },
              // 版本
              {
                path: '/sVersion',
                name: 'sVersion',
                component: () => import('@/views/Home/serviceMange/sVersion'),
                meta: {
                  title: "版本",
                  activeMenu: "/serviceMange"
                },
                children: [

                  {
                    path: '/sVersionD',
                    name: 'sVersionD',
                    component: () => import('@/views/Home/serviceMange/sVersionD'),
                    meta: {
                      title: "版本详情",
                      activeMenu: "/serviceMange"
                    },
                  }
                ]
              },
              // 分区
              {
                path: '/sPartition',
                name: 'sPartition',
                component: () => import('@/views/Home/serviceMange/sPartition'),
                meta: {
                  title: "分区",
                  activeMenu: "/serviceMange"
                }, children: [

                  {
                    path: '/sPartitionD',
                    name: 'sPartitionD',
                    component: () => import('@/views/Home/serviceMange/sPartitionD'),
                    meta: {
                      title: "分区详情",
                      activeMenu: "/serviceMange"
                    },
                  }
                ]
              },
              // GM账户
              {
                path: '/serviceGm',
                name: 'serviceGm',
                component: () => import('@/views/Home/serviceMange/serviceGm'),
                meta: {
                  title: "GM账户",
                  activeMenu: "/serviceMange"
                },
                children: [
                  {
                    path: '/serviceGmD',
                    name: 'serviceGmD',
                    component: () => import('@/views/Home/serviceMange/serviceGmD'),
                    meta: {
                      title: "GM账户详情",
                      activeMenu: "/serviceMange"
                    },
                  }
                ]
              },
            ]
          },

        ]
      },
      {
        path: '/GMManagement',
        name: 'GMManagement',
        meta: {
          title: "GM管理",
          activeMenu: "/GMManagement"
        },
        component: () => import('@/views/Home/GMmanagement/GMManagement'),
        children: [
          {
            path: '/addManagement',
            name: 'addManagement',
            component: () => import('@/views/Home/GMmanagement/Gmchirld/addManagement'),
            meta: {
              title: "GM账号详情",
              activeMenu: "/GMManagement"
            },
            children: [
              {
                path: '/Management1',
                name: 'Management1',
                component: () => import('@/views/Home/GMmanagement/Gmchirld/Management1'),
                meta: {
                  title: "账号详情",
                  activeMenu: "/GMManagement"
                },

              },
              {
                path: '/Management2',
                name: 'Management2',
                component: () => import('@/views/Home/GMmanagement/Gmchirld/Management2'),
                meta: {
                  title: "累计充值",
                  activeMenu: "/GMManagement"
                },
                children: [
                  {
                    path: '/OrderDetails',
                    name: 'OrderDetails',
                    component: () => import('@/views/Home/GMmanagement/Gmchirld/OrderDetails'),
                    meta: {
                      title: "订单详情",
                      activeMenu: "/GMManagement"
                    },
                  },
                ]

              },
            ]
          },
        ]
      },
      // 财务管理的账户管理
      {
        path: '/accountMange',
        name: 'accountMange',
        meta: {
          title: "账户管理",
          activeMenu: "/accountMange"
        },
        component: () => import('@/views/Home/financialMange/accountMange'),
        children: [
          {
            path: '/accountInfo',
            name: 'accountInfo',
            component: () => import('@/views/Home/financialMange/accountMangeC/accountInfo'),
            meta: {
              title: "账户信息",
              activeMenu: "/accountMange"
            },
            children: [
              {
                path: '/fineD',
                name: 'fineD',
                component: () => import('@/views/Home/financialMange/accountMangeC/fineD'),
                meta: {
                  title: "罚款详情",
                  activeMenu: "/accountMange"
                },
              },
              {
                path: '/freezeD',
                name: 'freezeD',
                component: () => import('@/views/Home/financialMange/accountMangeC/freezeD'),
                meta: {
                  title: "冻结详情",
                  activeMenu: "/accountMange"
                },
              },
              {
                path: '/thawD',
                name: 'thawD',
                component: () => import('@/views/Home/financialMange/accountMangeC/thawD'),
                meta: {
                  title: "解冻详情",
                  activeMenu: "/accountMange"
                },
              },
              {
                path: '/revokeD',
                name: 'revokeD',
                component: () => import('@/views/Home/financialMange/accountMangeC/revokeD'),
                meta: {
                  title: "撤销详情",
                  activeMenu: "/accountMange"
                },
              },
            ]
          },
        ]
      },
      // 订单管理
      {
        path: '/orderMange',
        name: 'orderMange',
        meta: {
          title: "订单管理",
          activeMenu: "/orderMange"
        },
        component: () => import('@/views/Home/financialMange/orderMange'),
        children: [
          {
            path: '/orderD',
            name: 'orderD',
            component: () => import('@/views/Home/financialMange/accountMangeC/orderD'),
            meta: {
              title: "订单详情",
              activeMenu: "/accountMange"
            },
          },]
      },
      {
        path: '/settleMange',
        name: 'settleMange',
        meta: {
          title: "结算管理",
          activeMenu: "/settleMange"
        },
        component: () => import('@/views/Home/financialMange/settleMange'),
        children: [
          {
            path: '/Settlement',
            name: 'Settlement',
            meta: {
              title: '结算单',
              activeMenu: '/settleMange'
            },
            component: () => import('@/views/Home/financialMange/SettMangeChildren/Settlement')
          },
          {
            path: '/rechargeOrder',
            name: 'rechargeOrder',
            meta: {
              title: '充值订单',
              activeMenu: '/settleMange'
            },
            component: () => import('@/views/Home/financialMange/SettMangeChildren/rechargeOrder'),
            children: [
              {
                path: '/SorderDetails',
                name: 'SorderDetails',
                meta: {
                  title: '订单详情',
                  activeMenu: '/settleMange'
                },
                component: () => import('@/views/Home/financialMange/SettMangeChildren/SorderDetails.vue')
              },
            ]
          },

        ]
      },
      {
        path: '/financeSet',
        name: 'financeSet',
        meta: {
          title: "财务设置",
          activeMenu: "/financeSet"
        },
        component: () => import('@/views/Home/financialMange/financeSet'),
      },
      // 订单管理
      {
        path: '/orderM',
        name: 'orderM',
        meta: {
          title: "订单管理",
          activeMenu: "/orderM"
        },
        component: () => import('@/views/Home/orderM/orderM'),
      },
    ]
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue'),

  }
]
const router = createRouter({
  // 第二步 路由路也要配置 （因为要在同一域名或同一端口下放多个项目）

  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  let token = getToken();
  if (to.name !== "Login") {
    if (!token) {
      // window.$message.error("登陆信息过期，请重新登陆");
      next("/Login");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router
